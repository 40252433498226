function UlsyFlexibleBox(sectionId) {
    this.section = $(sectionId);
    this.opened = null;
  
}

UlsyFlexibleBox.prototype.open = function (id) {
    this.opened = $(id);
    this.opened.addClass('open');
    $('.flexible-sidebar').addClass('open');
    $('.top-bar').css("z-index", "0");
}

UlsyFlexibleBox.prototype.close = function () {
  if(this.opened){
    this.opened.removeClass('open');
    $('.flexible-sidebar').removeClass('open');
    
  }else{
    $('.flexible.open').removeClass('open');
  }
  
  $('.top-bar').css("z-index", "");
  
}


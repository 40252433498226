function UlsyMenu(params){
  var self = this;

  this.visible = false;
  this._default = {
    selector : ".top-bar",
    button : "#top-bar-button"
  }

  if(params !== {}){
    for(var prop in params){
      if(self._default[prop]){
        self._default[prop] = params[prop];
      }else{
        throw "There is no such parameter in _defualt : "+prop;
      }
    }
  }
}

UlsyMenu.prototype.show = function(){
  $(this._default.selector).addClass("active");
  $('#top-bar-button').addClass('is-active');

  this.visible = true;

}

UlsyMenu.prototype.hide = function(){

  $(this._default.selector).removeClass("active");
  $('#top-bar-button').removeClass('is-active');
  this.visible = false;
}

UlsyMenu.prototype.toggle = function(){
  if(this.visible){
    this.hide();
  }else{
    this.show();
  }
}

UlsyMenu.prototype.setButtonColor = function(color){
  switch(color){
    case "white":
      $('#top-bar-button').addClass('white');
    break;

    case "black":
      $('#top-bar-button').removeClass('white');
    break;

    default:
      $('#top-bar-button').removeClass('white');
    break;
  }
}

UlsyMenu.prototype.setActive = function(index){

  $('.menu__list .menu__list-item').removeClass('active');
  $('.menu__list .menu__list-item:nth-child('+index+')').addClass('active');
}
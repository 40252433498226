function RealizationsManager(selector, slider){
  var self = this; 

  this.slider = slider;
  this.selector = selector;
  $(selector).on('click', function(e){
    e.preventDefault();
    self.change($(this).attr('category'));
  })
}

RealizationsManager.prototype.switchContent = function(html){
  $('#realizations-slider .swiper-wrapper').html(html);
  this.slider.update();
}

RealizationsManager.prototype.change = function(category = "all"){
  var self  = this;
  self.setLoading(category);
  $('#realizations-slider').fadeOut(200, function(){
    $.ajax({
      url: "/", 
      method: 'POST',
      data: {
        category : category,
        ajax : true
      },
      success: function(result){
        self.switchContent(result);
        self.setActive(category);
      }
    });
  });
  
}

RealizationsManager.prototype.setActive = function (category = "all"){
  $(this.selector).removeClass('active');
  $(this.selector).removeClass('loading');
  $(this.selector+'[category="'+category+'"]').addClass("active");
  $('#realizations-slider').fadeIn();
  
}

RealizationsManager.prototype.setLoading = function (category = "all") {
  $(this.selector+'[category="'+category+'"]').addClass("loading");
  
}

